import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { Box, Typography, Link, Container, Button } from "@material-ui/core";

import ChevronRightIcon from "@material-ui/icons/ChevronRight";

import {
  AdsTop,
  AdsBottom,
  AdsIncontent
} from "app/components/ads/freestar";

import CoursesCarousel from "app/components/carousels/Courses";
import EbooksCarousel from "app/components/carousels/Ebooks";
import Campaigns from "app/components/carousels/Campaigns";

import DialogRedeemVoucher from "app/components/Modals/RedeemVoucher";
import { useStyles } from "./styles";

export default (props) => {
  const classes = useStyles();
  const history = useHistory();

  const [openRedeemVoucherDialog, setOpenRedeemVoucherDialog] = useState(false);

  const handleRedeemVoucherDialog = (open) => (event) => {
    setOpenRedeemVoucherDialog(open);
  };

  const goTo = (url) => {
    if (!url) {
      return;
    }
    history.push(`${url}`);
  };

  return (
    <React.Fragment>
      <Box component="div" mb={3}>
        <Container maxWidth="xl">
          <Box component="div" mb={4} display="flex" flexDirection="row" justifyContent="right">
            <Button
              style={{marginRight: 10}}
              size='small'
              disableElevation={false}
              onClick={() => history.push('/dashboard/referrals')}
            >
              <span role='img' aria-label='gift' style={{fontSize: 18, marginRight: 10}}>🎁</span>
              Indique e Ganhe
            </Button>
            <Button
              size='small'
              disableElevation={false}
              onClick={handleRedeemVoucherDialog(true)}
            >
              <span role='img' aria-label='gift' style={{fontSize: 18, marginRight: 10}}>🎟️</span>
              Resgatar Assinatura
            </Button>
          </Box>
        </Container>

        <Box component="div" mb={2}>
          <Container maxWidth="md">
            <Campaigns context='dashboard' />
          </Container>
        </Box>

        <Box component="div" mb={3} px={3}>
          <Typography
            component="h2"
            variant="h1"
            className={classes.discoveryTitle}
          >
            Explore, aprenda, pratique
          </Typography>
          <Typography component="p" className={classes.discoverySubtitle}>
            Estude gratuitamente em quantas áreas quiser. Sem limites.
            <br /> Aqui estão algumas sugestões para você!
            <br /> Vamos começar? 😃
          </Typography>
        </Box>

        <Box component="div" mb={3} px={{ xs: 2, sm: 2, md: 3 }}>
          <AdsTop />
        </Box>

        <Box mb={3} component="section" pl={3} pr={{ xs: 2, sm: 2, md: 0 }}>
          <Box component="div" display="flex" flexDirection="row" mb={2}>
            <Typography
              component="h3"
              variant="h3"
              className={classes.sectionTitle}
            >
              Populares na Kultivi
            </Typography>
            <Link
              component="button"
              onClick={() => goTo("/dashboard/discover")}
              className={classes.seeMore}
            >
              Ver tudo <ChevronRightIcon />
            </Link>
          </Box>
          <CoursesCarousel feature="popular" />
        </Box>

        <Box component="div" mb={4} px={{ xs: 2, sm: 2, md: 3 }}>
          <AdsIncontent />
        </Box>

        <Box mb={3} component="section" pl={3} pr={{ xs: 2, sm: 2, md: 0 }}>
          <Box component="div" display="flex" flexDirection="row" mb={2}>
            <Typography
              component="h3"
              variant="h3"
              className={classes.sectionTitle}
            >
              Últimos lançamentos
            </Typography>
            <Link
              component="button"
              onClick={() => goTo("/dashboard/discover")}
              className={classes.seeMore}
            >
              Ver tudo <ChevronRightIcon />
            </Link>
          </Box>
          <CoursesCarousel feature="latest" />
        </Box>

        <Box component="div" mb={4} px={{ xs: 2, sm: 2, md: 3 }}>
          <AdsIncontent />
        </Box>

        <Box mb={3} component="section" pl={3} pr={{ xs: 2, sm: 2, md: 0 }}>
          <Box component="div" display="flex" flexDirection="row" mb={2}>
            <Typography
              component="h3"
              variant="h3"
              className={classes.sectionTitle}
            >
              Mais bem avaliados
            </Typography>
            <Link
              component="button"
              onClick={() => goTo("/dashboard/discover")}
              className={classes.seeMore}
            >
              Ver tudo <ChevronRightIcon />
            </Link>
          </Box>
          <CoursesCarousel feature="featured" />
        </Box>

        <Box component="div" mb={4} px={{ xs: 2, sm: 2, md: 3 }}>
          <AdsIncontent />
        </Box>

        <Box mb={3} component="section" pl={3} pr={{ xs: 2, sm: 2, md: 0 }}>
          <Box component="div" display="flex" flexDirection="row" mb={2}>
            <Typography
              component="h3"
              variant="h3"
              className={classes.sectionTitle}
            >
              E-books
            </Typography>
            <Link
              component="button"
              onClick={() => goTo("/dashboard/ebooks")}
              className={classes.seeMore}
            >
              Ver tudo <ChevronRightIcon />
            </Link>
          </Box>
          <EbooksCarousel feature='featured' />
        </Box>

        <Box component="div" mb={4} px={{ xs: 2, sm: 2, md: 3 }}>
          <AdsBottom />
        </Box>

        <DialogRedeemVoucher
          open={openRedeemVoucherDialog}
          toggleDialog={handleRedeemVoucherDialog}
        />

      </Box>
    </React.Fragment>
  );
};
